// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;

@include mat.core();
$vertice-booking-primary: mat.define-palette(mat.$red-palette);
$vertice-booking-primary-form-field: mat.define-palette(mat.$indigo-palette);
$vertice-booking-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$vertice-booking-warn: mat.define-palette(mat.$red-palette);


$vertice-booking-theme: mat.define-light-theme((
  color: (
    primary: $vertice-booking-primary,
    accent: $vertice-booking-accent,
    warn: $vertice-booking-warn,
  )
));

$vertice-booking-theme-form-field: mat.define-light-theme((
  color: (
    primary: $vertice-booking-primary-form-field,
    accent: $vertice-booking-accent,
    warn: $vertice-booking-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.checkbox-theme($vertice-booking-theme);
@include mat.checkbox-color($vertice-booking-theme);
@include mat.all-component-themes($vertice-booking-theme);
@include mat.form-field-theme($vertice-booking-theme-form-field);

body {
  padding: 0;
  margin: 0;
  box-sizing: 0;
}

@import '~mdb-angular-ui-kit/assets/scss/mdb.scss';

@font-face {
  font-family: Inter-Black;
  src: url(assets/font/Inter-Black.ttf);
}

@font-face {
  font-family: Inter-Bold;
  src: url(assets/font/Inter-Bold.ttf);
}

@font-face {
  font-family: Inter-ExtraBold;
  src: url(assets/font/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: Inter-ExtraLight;
  src: url(assets/font/Inter-ExtraLight.ttf);
}

@font-face {
  font-family: Inter-Light;
  src: url(assets/font/Inter-Light.ttf);
}

@font-face {
  font-family: Inter-Medium;
  src: url(assets/font/Inter-Medium.ttf);
}

@font-face {
  font-family: Inter-Regular;
  src: url(assets/font/Inter-Regular.ttf);
}

@font-face {
  font-family: Inter-SemiBold;
  src: url(assets/font/Inter-SemiBold.ttf);
}

@font-face {
  font-family: Inter-Thin;
  src: url(assets/font/Inter-Thin.ttf);
}


.font-bd-s18 {
  font-family: Inter-Bold;
  font-size: 18px;
  color: #231D1A;
}

.font-bd-s20-black {
  font-family: Inter-Bold;
  font-size: 20px;
  color: black;
}

.font-smb-s16-black {
  font-family: Inter-SemiBold;
  font-size: 16px;
  color: #231D1A;
}

.font-smb-s18-black {
  font-family: Inter-SemiBold;
  font-size: 18px;
  color: #231D1A;
}

.font-smb-s14-nc {
  font-family: Inter-SemiBold;
  font-size: 16px;
  color: #E1251B
}

.font-bd-s14 {
  font-family: Inter-Bold;
  font-size: 14px;
  color: #231D1A;
}

.font-bd-s16 {
  font-family: Inter-Bold;
  font-size: 16px;
  color: #231D1A;
}

.font-bd-s20-black {
  font-family: Inter-Bold;
  font-size: 20px;
  color: black;
}

.font-bd-s16-black {
  font-family: Inter-Bold;
  font-size: 16px;
  color: black;
}

.font-sbd-s18-black {
  font-family: Inter-SemiBold;
  font-size: 18px;
  color: black;
}

.font-rl-s12-red {
  font-family: Inter-Regular;
  font-size: 12px;
  color: #E1251B;
}

.font-rl-s14-red {
  font-family: Inter-Regular;
  font-size: 14px;
  color: #E1251B;
}

.font-rl-s15-black {
  font-family: Inter-Regular;
  font-size: 15px;
  color: black;
}


.font-rl-s12-gray {
  font-family: Inter-Regular;
  font-size: 12px;
  color: #5B6670;
}

.font-rl-s14-gray {
  font-family: Inter-Regular;
  font-size: 14px;
  color: #5B6670;
}


.font-rl-s16-black {
  font-family: Inter-Regular;
  font-size: 16px;
  color: black;
}

.font-im-s18-black {
  font-family: Inter-Medium;
  font-size: 18px;
  color: black;
}


.font-rl-s16-white {
  font-family: Inter-Regular;
  font-size: 16px;
  color: white;
}

.font-sbd-s14-red {
  font-family: Inter-SemiBold;
  font-size: 14px;
  color: #E1251B;
}

.font-sbd-s16-red {
  font-family: Inter-SemiBold;
  font-size: 16px;
  color: #E1251B;
}

.font-sbd-s14-white {
  font-family: Inter-SemiBold;
  font-size: 14px;
  color: white;
}

.font-sbd-s16-white {
  font-family: Inter-SemiBold;
  font-size: 16px;
  color: white;
}

.font-im-s16-black {
  font-family: Inter-Medium;
  font-size: 16px;
  color: #070708;
}


.font-im-s14-black {
  font-family: Inter-Medium;
  font-size: 14px;
  color: #070708;
}

.font-s12 {
  font-family: Inter-Regular;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #0f0f0f;
}


.font-s12-gray {
  font-family: Inter-Regular;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  color: #5B6670;
}

.font-rl-s14 {
  font-family: Inter-Regular;
  font-style: normal;
  font-size: 14px;
  line-height: 19px;
  color: #231D1A;
}

.font-rl-s16 {
  font-family: Inter-Regular;
  font-style: normal;
  line-height: 19px;
  color: #231D1A;
}

.btn {
  text-transform: none;
}

a {
  color: #231D1A
}

.grecaptcha-badge {
  visibility: hidden;
}

ul {
  padding-left: 1rem !important;
}

mdb-modal-container::ng-deep div.modal-dialog.modal-dialog-centered {
  display: flex !important;
  justify-content: center !important;
}
